<template>
  <div v-loading="loading" class="custom-dashboard-scheduler">
    <el-row></el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loadingText: "Getting info..",
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-dashboard-scheduler {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  // border-top: 1px solid #eaeaea;
  // border-right: 1px solid #eaeaea;
  // border-left: 1px solid #eaeaea;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
}
</style>
